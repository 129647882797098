import { gql } from '@apollo/client'

export const SUBSCRIBE_TO_NEWSLETTER = gql`
    mutation SubscribeToNewsletter (
        $email: String, 
        $categories: [Category!],
        $firstname: String,
        $lastname: String,
        $country: String
    ) {
        sendinblueSignUp(
            email: $email,
            categories: $categories,
            firstname: $firstname,
            lastname: $lastname,
            country: $country
        )
    }
`
