import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { client } from '../services/client'
import { GET_CATEGORIES } from '../services/graphql/queries/getCategories'
import { GET_FEATURED_PLUGINS } from '../services/graphql/queries/getFeaturedPlugins'
import { GET_POPULAR_PLUGINS_BY_SOFTWARE } from '../services/graphql/queries/getPopularPlugins'
import { GET_COUNTRIES } from '../services/graphql/queries/getCountries'
import HomePopularPlugins from '../components/home/HomePopularPlugins'
import HomeFeaturedPlugins from '../components/home/HomeFeaturedPlugins'
import HomeCategories from '../components/home/HomeCategories'
import HomeNewsletter from '../components/home/HomeNewsletter'
import HomeSearchBar from '../components/home/HomeSearchBar'
import Ad from '../components/Ad'
import Layout from '../components/layouts/AppLayout'
import Head from 'next/head'

export default function Home ({ categories, featuredPlugins, popularPlugins, countries, allCategories }) {
  const { t } = useTranslation(['home', 'common', 'categories'])
  const applicationLoaded = useSelector(state => state.app.hasLoaded)

  const sortCategories = (categories) => {
    if (!categories) {
      return []
    }

    return [...categories]
      .map(category => {
        const newCategory = { ...category }

        newCategory.translatedName = t(category.name, { ns: 'categories' })

        return newCategory
      })
      .sort((a, b) => a.translatedName.localeCompare(b.translatedName))
  }

  categories = sortCategories(categories)
  allCategories = sortCategories(allCategories)

  useEffect(() => {
    if (!applicationLoaded) {
      return
    }

    document
      .querySelectorAll('[data-bs-toggle="tooltip"]')
      .forEach(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl))
  }, [applicationLoaded])

  return (
    <Layout variant="white">
      <Head>
        <title>{t('meta.title')}</title>
      </Head>

      <HomeSearchBar />

      <div className="container">
        <div className="row text-center mt-3 d-none d-xl-block">
          <div className="col-12">
            <Ad
              zoneId="3"
              loadScript={true}
            />
          </div>
        </div>
      </div>

      <HomeCategories categories={categories} />

      <div className="container">
        <div className="row text-center mt-3 mb-3 d-none d-xl-block">
          <div className="col-12">
            <Ad
              zoneId="4"
              loadScript={false}
            />
          </div>
        </div>
      </div>

      <HomeFeaturedPlugins plugins={featuredPlugins} />
      {
        false && popularPlugins
          .filter(softwarePlugin => softwarePlugin.plugins.length > 0)
          .length > 0 && (
          <HomePopularPlugins plugins={popularPlugins} />
        )
      }
      <section
        className={'home__marketplace text-center text-white d-flex justify-content-center flex-column'}
        id="homeNewsletter"
      >
        <div className="container">
          <h2 className="fw-semibold fs-1">{t('newsletter.title')}</h2>
          <p>{t('newsletter.description')}</p>
          <HomeNewsletter
            categories={allCategories}
            countries={countries}
          />
        </div>
      </section>
    </Layout>
  )
}

export async function getServerSideProps ({ locale }) {
  const getVariablesForPopularPlugins = software => ({
    variables: {
      hasCompatibilities: {
        column: 'SOFTWARE', operator: 'EQ', value: software
      }
    }
  })

  const [{ data: categoryRows }, { data: featuredPlugins }, { data: popularAutoCAD }, { data: popularBricsCAD }, { data: countryCollection }, { data: allCategoryRows }] = await Promise.all([
    client(locale).query({
      query: GET_CATEGORIES,
      variables: {
        active_only: true
      }
    }),
    client(locale).query({ query: GET_FEATURED_PLUGINS }),
    client(locale).query({ query: GET_POPULAR_PLUGINS_BY_SOFTWARE, ...getVariablesForPopularPlugins(2) }),
    client(locale).query({ query: GET_POPULAR_PLUGINS_BY_SOFTWARE, ...getVariablesForPopularPlugins(1) }),
    client(locale).query({ query: GET_COUNTRIES }),
    client(locale).query({
      query: GET_CATEGORIES,
      variables: {
        active_only: false
      }
    })
  ])

  return {
    props: {
      categories: categoryRows.categories,
      allCategories: allCategoryRows.categories,
      featuredPlugins: featuredPlugins.featuredProducts,
      popularPlugins: [
        {
          software: 'AUTOCAD',
          plugins: popularAutoCAD.popularProductsBySoftware
        },
        {
          software: 'BRICSCAD',
          plugins: popularBricsCAD.popularProductsBySoftware
        }
      ],
      countries: countryCollection.countries,
      ...(await serverSideTranslations(locale, ['common', 'home', 'footer', 'header', 'categories', 'languages', 'applications', 'plugins', 'countries', 'seller', 'routes']))
    }
  }
}
