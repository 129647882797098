import { useTranslation } from 'next-i18next'
import PluginsFilterSearch from '../plugins/filter/PluginsFilterSearch'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

export default function HomeSearchBar () {
  const { t } = useTranslation('home')
  const router = useRouter()
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!search) return

    router.push(`/plugins?search=${search}`)
  }, [search])

  return (
    <div className={'home__intro cad-container align-items-center d-flex'}>
      <div className="container text-white">
        <div className="row align-items-center justify-content-start">
          <div className="row">
            <div className="col-12 col-lg-6">
              <PluginsFilterSearch
                placeholder={t('hero.search')}
                search={search}
                onSearchChange={setSearch}
              />
              <span className="fs-5">{t('hero.description')}</span>
              <h1 className="fs-4 text-turquoise mt-2 mb-3">
                {t('hero.title')}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
