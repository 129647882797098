import PluginBigLogo from '../plugins/PluginBigLogo'
import { useTranslation } from 'next-i18next'

export default function HomeFeaturedPlugins ({ plugins }) {
  const { t } = useTranslation(['home', 'categories', 'plugins'])
  const featuredPlugins = plugins.map(plugin => (
    <PluginBigLogo
      plugin={plugin}
      key={plugin.id}
      hideDescription={true}
      className="plugincard plugincard--featured col-12 col-md-6 col-lg-3"
    />
  ))

  return (
    <div className="bg-light-blue py-5">
      <div className="container">
        <div className="row mb-3">
          <div className="col-12 col-md-auto">
            <span className="badge bg-light text-primary rounded-2 text-uppercase mb-2 px-3 fw-semibold">{t('featuredPlugins.subtitle')}</span>
            <h2 className=" fw-bold text-dark">{t('featuredPlugins.title')}</h2>
          </div>
        </div>
        <div className="row py-lg-3 g-4">
          {featuredPlugins}
        </div>
      </div>
    </div>
  )
}
