import Link from 'next/link'
import HomeSupport from './HomeSupport'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import useTranslatedRoutes from '../../services/translatedRoutes'

export default function HomeCategories ({ categories }) {
  const { t } = useTranslation(['home', 'categories'])
  const router = useRouter()
  const language = router.locale.toUpperCase()
  const { getCategoryUrl } = useTranslatedRoutes(language)

  return (
    <section className="home__categories appendix--bottomleft appendix--primary py-5">
      <div className="container">
        <div className="row justify-content-between align-items-center mb-3 pt-lg-5">
          <div className="col-12 col-md-auto">
            <span className="badge bg-light text-primary rounded-2 text-uppercase mb-3 px-2 fw-semibold">{t('categories.subtitle')}</span>
            <h2 className=" fw-bold text-dark">{t('categories.title')}</h2>
          </div>
          <Link href="/categories">
            <a className="d-none d-md-block col-md-auto fw-bold text-decoration-none arrowlink">
              <span>{t('categories.all')}</span>
              <svg className="ms-2"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.2 8H0.800049"
                  stroke="currentColor"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M11.2 4L15.2 8L11.2 12"
                  stroke="currentColor"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </Link>
        </div>
        <div className="categories row py-lg-3 g-2 g-lg-4">
          {
            categories.slice(0, 16).map((category, index) => (
              <Link
                href={getCategoryUrl(category)}
                key={index}
              >
                <a className="col-6 col-md-4 col-lg-3 text-decoration-none">
                  <div className="categorypanel bg-light p-3 w-100 d-flex d-flex align-items-center text-dark rounded justify-content-between">
                    <h3 className="fs-default fw-normal mb-0">
                      {t(category.name, { ns: 'categories' })}
                    </h3>
                    <svg className="ms-2"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M15.2 8H0.800049"
                        stroke="currentColor"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path d="M11.2 4L15.2 8L11.2 12"
                        stroke="currentColor"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </a>
              </Link>
            ))
          }
        </div>
        <div className="row d-flex justify-content-end mt-5 mt-lg-4">
          <div className="col-auto">
            <Link href="/categories">
              <a className="d-md-none fw-bold text-decoration-none arrowlink">
                <span>{t('categories.all')}</span>
                <svg className="ms-2"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.2 8H0.800049"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M11.2 4L15.2 8L11.2 12"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </Link>
          </div>
        </div>
        <HomeSupport />
      </div>
    </section>
  )
}
