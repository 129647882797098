import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { SUBSCRIBE_TO_NEWSLETTER } from '../../services/graphql/mutations/subscribeToNewsletter'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import Select from 'react-select'
import { useCountries } from '../../hooks/useCountries'

export default function HomeNewsletter ({ categories, countries }) {
  const [subscribeToNewsletter] = useMutation(SUBSCRIBE_TO_NEWSLETTER)
  const { t } = useTranslation(['home', 'countries', 'seller', 'categories'])
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCategories, setSelectedCategories] = useState([])
  const { translate } = useCountries()

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      await subscribeToNewsletter({
        variables: {
          firstname: firstName,
          lastname: lastName,
          email,
          categories: selectedCategories.map(category => category.value),
          country: selectedCountry.value
        }
      })

      setFirstName('')
      setLastName('')
      setEmail('')
      setSelectedCategories([])
      setSelectedCountry('')

      toast.success(t('hero.newsletter.success'))
    } catch (e) {
      toast.error(t('hero.newsletter.error'))
    }
  }

  return (
    <form onSubmit={e => handleSubmit(e)}>
      <div className="container d-grid justify-content-center">
        <div className="row g-3 my-2">
          <div className="col-lg-6">
            <input required
              type="text"
              placeholder={t('detail.form.firstname', { ns: 'seller' })}
              className="form-control h-100"
              id="firstName"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
          </div>
          <div className="col-lg-6">
            <input required
              type="text"
              placeholder={t('detail.form.lastname', { ns: 'seller' })}
              className="form-control h-100"
              id="lastName"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="row g-3 my-2">
          <div className="col-lg-6">
            <input
              type="text"
              placeholder={t('detail.form.email', { ns: 'seller' })}
              className="form-control h-100"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="col-12 col-md-5 col-lg-6">
            <Select
              placeholder={t('hero.country_select')}
              instanceId="countriesSelect"
              isMulti={false}
              classNamePrefix="app-select"
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  maxHeight: '60px',
                  overflowY: 'scroll'
                })
              }}
              options={countries.map(country => ({ value: country.code, label: translate(country) }))}
              onChange={setSelectedCountry}
              value={selectedCountry}
              className="text-darkblue"
            />
          </div>
        </div>
        <div className="row g-3 my-2">
          <div className="col-lg-8">
            <Select
              placeholder={t('select', { ns: 'common' })}
              instanceId="categoriesSelect"
              isMulti={true}
              classNamePrefix="app-select"
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  maxHeight: '60px',
                  overflowY: 'scroll'
                })
              }}
              options={categories.map(category => ({ value: category.name, label: t(category.name, { ns: 'categories' }) }))}
              onChange={setSelectedCategories}
              value={selectedCategories}
              className="text-darkblue"
            />
          </div>
          <div className="col-12 col-lg-4">
            <input
              type="submit"
              value={t('sign_up', { ns: 'common' })}
              className="btn btn-primary w-100 h-100"
            />
          </div>
        </div>
      </div>
    </form>
  )
}
